import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import rootReducer from "../reducer";
import thunkMiddleware from "redux-thunk";

const newReducer = combineReducers({
  rootReducer: rootReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  newReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
